.app {
  background-color: #333;
  min-height: 100vh;
  height: 100%;
  overflow-y: hidden;

  &__content {
    overflow-y: auto;

    @include mobile {
      position: relative;
      // height of mobile navbar
      top: 82px;
    }
  }
}
