.request-quote-section {
  @include section;
  
  background-color: colors('tertiary');
  color: colors('merit-blue');

  &__header {
    @include section-header;
  }

  &__body {
    @include section-content;
  }

  &__quote-form {
    max-width: 600px;
    margin: 0 auto;
  }

  &__button-container {
    text-align: center;
  }

  &__submit-button {
    background-color: colors('merit-blue');
    border: none;
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 600;
    padding: 12px 16px;
    text-transform: uppercase;
    min-width: 250px;
    border-radius: 5px;
    box-sizing: border-box;
    outline: none;

    &:hover,
    &:focus,
    &:active {
      background-color: rgba(0,138,193, 0.65);
    }
  }

  &__form--submitted {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__form-message {
    @include paragraph-1;
  }
}
