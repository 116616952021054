.equipment-section {
  $this: &;
  
  @include section;

  background-color: colors('tertiary');
  color: colors('text-primary');

  &__header {
    @include section-header;
  }

  &__body {
    @include section-content;
  }

  // Machine Block
  &__machine {
    display: flex;

    @include mobile {
      flex-direction: column;
      
      &:not(:last-child) {
        margin-bottom: 16px;
      }
    }

    &--alternate {
      #{$this}__image-container {
        // Switch order of image & info
        order: 1;

        @include mobile {
          order: 0;
        }
      }
    }
  }

  &__image-container,
  &__info {
    box-sizing: border-box;
    width: 50%;

    @include mobile {
      width: 100%;
    }
  }

  &__image {
    max-width: 100%;
    max-height: 100%;
  }

  &__info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 12px 16px;
  }

  &__block-header {
    font-size: 18px;
    font-weight: 600;
  }

  &__description-item {
    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }
}