@mixin mobile {
  @media (max-width: 768px) {
    @content;
  }
}

@mixin landscape-iphone {
  @media (max-width: 812px) {
    @content;
  }
}

@mixin portrait-iphone {
  @media (orientation: portrait) and (max-width: 414px) {
    @content;
  }
}
