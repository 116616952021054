.about-us-section {
  $this: &;

  @include section;

  background-color: colors('merit-blue');
  color: colors('text-secondary');

  &__header {
    @include section-header;
  }

  &__body {
    @include section-content;
    @include paragraph-1;
  }

  &__products {
    display: flex;
    height: 350px;
    justify-content: space-evenly;
    flex-wrap: wrap;

    @include mobile {
      flex-direction: column;
      height: auto;
    }
  }

  &__product {
    flex: 1;
    padding: 10px;

    @include mobile {
      padding: 5px;
    }
  }

  &__product-image {
    max-width: 100%;
    max-height: 100%;
  }
}