.footer {
  background-color: colors('merit-blue');
  color: colors('text-secondary');
  padding: 48px;

  @include mobile {
    padding: 32px 24px 110px;
  }

  &__content {
    display: flex;

    max-width: 825px;
    margin-left: auto;
    margin-right: auto;

    @include mobile {
      flex-direction: column;
    }
  }

  &__column {
    max-width: 200px;
    width: 100%;

    @include mobile {
      max-width: 100%;
    }

    &:not(:last-child) {
      margin-right: 48px;

      @include mobile {
        margin-right: 0;
        margin-bottom: 32px;
      }
    }

    &--logo {
      width: auto;
    }

    &--logo,
    &--links {
      @include mobile {
        display: none;
      }
    }
  }

  &__column-header {
    @include header-2;

    border-top: 5px solid colors('grey');
    margin-bottom: 16px;
    padding-top: 8px;
    text-transform: uppercase;
  }

  &__column-body {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
  }

  &__link {
    color: colors('text-secondary');
    line-height: 22px;
    text-decoration: none;
    text-transform: uppercase;

    &--email {
      text-transform: lowercase;
    }
    
    &:hover {
      color: colors('light-gray');
    }
  }

  &__logo {
    width: 75px;
  }
}
