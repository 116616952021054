$colors: (
  merit-blue: #008AC1,
  secondary: #FFFFFF,
  tertiary: #EFEFEF,
  grey: #333,
  
  text-primary: #605E5E,
  text-secondary: #FFFFFF,
  text-grey: #333,

  light-gray: #C1C1C1,
);
