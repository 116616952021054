.logo {
  height: 100px;

  @include mobile {
    height: 55px;
  }
  
  path {
    stroke: colors('merit-blue');
    fill: colors('merit-blue');
  }

  &--inverted {
    path {
      stroke: colors('secondary');
      fill: colors('secondary');
    }
  }
}