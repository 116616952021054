.navbar {
  $this: &;

  background-color: transparent;
  color: #FFFFFF;
  display: flex;
  // height: 75px;
  width: 100%;
  z-index: 3;

  @include mobile {
    background-color: #333;
    position: fixed;
    top: 0;
    height: 79px;
  }

  &--open {
    // #{$this}__logo-company-name {
    //   @include mobile {
    //     font-size: 16px;
    //   }
    // }

    #{$this}__nav-list {
      @include mobile {
        background-color: #333;
        min-height: calc(100vh - 79px);
        max-height: calc(100vh - 79px);
        height: 100%;
        box-sizing: border-box;
        width: 200px;
        padding-top: 21px;
        
        align-items: flex-start;
        display: flex;
        z-index: 1;
      }
    }
  }

  &__logo {
    align-items: center;
    display: flex;
    margin: 16px 32px;
    position: relative;
    
    @include mobile {
      margin: 12px 16px;
    }
  }

  &__logo-company-name {
    font-size: 28px;
    font-weight: 600;
    margin-left: 16px;

    @include mobile {
      margin-left: 12px;
    }
  }

  &__logo-maple-leaf {
    height: 25px;
    position: absolute;
    bottom: 33px;
    right: -24px;
    transform: rotate(-10deg);

    @include mobile {
      bottom: 10px;
    }
  }

  &__nav-list {
    display: flex;
    align-items: center;
    flex: 1;
    padding: 0 32px;
    justify-content: flex-end;

    @include mobile {
      // background-color: #262626;
      width: 100%;
  
      position: fixed;
      right: 0;
      top: 79px;
      left: 0;

      display: none;
      align-items: unset;
      flex-direction: column;
      justify-content: flex-start;
      padding: 0;
    }

    @include landscape-iphone {
      padding: 0 16px;
    }
  }

  &__nav-link {
    box-sizing: border-box;
    color: #FFFFFF;
    font-size: 14px;
    padding: 8px 16px;
    text-decoration: none;
    text-transform: uppercase;

    &:hover,
    &:focus,
    &:active {
      background-color: rgba(2, 138, 191, 0.08);
      color: colors('merit-blue');
    }

    @include mobile {
      width: 100%;
    }

    &--home {
      display: none;

      @include mobile {
        display: block;
      }
    }
  }
}
