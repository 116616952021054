@mixin header-1 {
  font-family: Helvetica, sans-serif;
  font-size: 85px;
  font-weight: 600;
  letter-spacing: -2px;
}

@mixin header-1-mobile {
  font-family: Helvetica, sans-serif;
  font-size: 38px;
  font-weight: 600;
  letter-spacing: -2px;
}

@mixin header-2 {
  font-family: Helvetica, sans-serif;
  font-size: 16px;
  font-weight: 600;
}

@mixin paragraph-1 {
  font-family: Helvetica, sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
}

@mixin hero {
  font-family: Helvetica, sans-serif;
  font-size: 64px;
  font-weight: lighter;
}