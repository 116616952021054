.form-element {
  $this: &;

  color: colors('text-primary');
  display: flex;
  flex-direction: column;
  padding-bottom: 24px;

  &--active {
    color: colors('merit-blue');

    #{$this}__label {
      color: colors('merit-blue');
    }

    #{$this}__input {
      border-bottom-color: colors('merit-blue');
    }
  }

  &__label {
    font-size: 13px;
  }

  &__input,
  &__textarea {
    -webkit-appearance: none;
    background-color: transparent;
    border: none;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: colors('text-primary');
    font-size: 16px;
    outline: none;
  }

  &__input {
    height: 28px;
  }

  &__textarea {
    padding-top: 16px;
    min-height: 100px;
    resize: vertical;
  }
}
