@mixin section {
  padding: 80px 32px;

  @include mobile {
    padding-left: 24px;
    padding-right: 24px;
  }
}

@mixin section-header {
  @include header-1;

  margin-bottom: 32px;
  text-align: center;
  text-transform: uppercase;

  @include mobile {
    @include header-1-mobile;
  }
}

@mixin section-content {
  max-width: 825px;
  margin-left: auto;
  margin-right: auto;
}
