.home-page {
  &__section {
    @include section;

    &--get-in-touch {
      background-color: colors('merit-blue');
      color: colors('secondary');
    }

    &--home {
      background-image: linear-gradient(to top left, transparent, 0%, rgba(0,0, 0, 0.65)  0%), url('../../src/images/machining.jpg');
      background-size: cover;
      box-sizing: border-box;
      min-height: 100vh;
      padding-top: 200px;
      margin: auto;
      // height of navbar
      margin-top: -132px;

      @include mobile {
        // height of mobile navbar
        margin-top: -79px;
      }

      @include portrait-iphone {
        background-position: 1289px 75px;
      }
    }
  }

  &__section-header {
    @include section-header;
  }

  &__section-content {
    @include section-content;
  }

  &__company-contact-info {
    display: flex;
    justify-content: space-evenly;
    font-weight: 600;
    line-height: 22px;

    @include mobile {
      flex-direction: column;
    }

    &-column {
      text-align: center;
    }
  }

  &__divider {
    border-color: colors('secondary');
    border-style: solid;
    border-width: 2px;
    margin-left: 0;
    margin-right: 0;

    @include mobile {
      width: 7em;
      margin-top: 24px;
      margin-bottom: 24px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__map {
    height: 410px;

    @include mobile {
      height: 240px;
    }

    iframe {
      height: 100%;
    }
  }

  &__hero {
    @include hero;

    color: #FFFFFF;
    margin: 0 auto;
    max-width: 1024px;

    @include mobile {
      max-width: 550px;
    }
  }

  &__link {
    color: colors('text-secondary');
    text-decoration: none;

    &:hover {
      color: colors('light-gray');
    }
  }
}
